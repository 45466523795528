import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Marquee from "react-fast-marquee"
import * as instaStyles from "./instagramFeed.module.scss"

const InstagramFeed = () => {
  const [marquee, setMarquee] = useState(true)
  useEffect(() => {
    if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      setMarquee(false)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/social-marquee/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              socialMarqueeImages {
                href
                alt
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allMarkdownRemark.edges.flatMap(edge =>
    edge.node.frontmatter.socialMarqueeImages.map(img => ({
      ...img,
      imageSrc: img.image.publicURL,
    }))
  )

  return (
    <section>
      <div className={instaStyles.headerWrapper}>
        <h2 className={instaStyles.joinUs}>Join us on Instagram</h2>
        <a
          href="https://www.instagram.com/moderneyephilly"
          target="__blank"
          className={instaStyles.instaHandle}
          rel="noreferrer"
          title="Join us on Instagram"
        >
          @moderneyephilly
        </a>
      </div>
      <div className={instaStyles.marqueeWrapper}>
        {marquee ? (
          <Marquee
            gradient={false}
            pauseOnHover={true}
            style={{ width: `100%` }}
            play={marquee}
          >
            {images.map((item, i) => (
              <a
                href={item.href}
                target="__blank"
                rel="noopener noreferrer"
                key={i}
                title="View on Instagram"
              >
                <img
                  src={item.imageSrc}
                  className={instaStyles.instaImage}
                  loading="eager"
                  alt={item.alt}
                />
              </a>
            ))}
          </Marquee>
        ) : (
          <div className={instaStyles.instaImageFlexWrapper}>
            {images.map((item, i) => (
              <a
                href={item.href}
                target="__blank"
                rel="noopener noreferrer"
                key={i}
                title="View on Instagram"
              >
                <img
                  src={item.imageSrc}
                  className={instaStyles.instaImage}
                  loading="eager"
                  alt={item.alt}
                />
              </a>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default InstagramFeed
