// extracted by mini-css-extract-plugin
export var appointmentOnly = "overlayModal-module--appointmentOnly--EtBeQ";
export var block = "overlayModal-module--block--Lz5Ui";
export var bulletinWrapper = "overlayModal-module--bulletinWrapper--WFUFd";
export var closeIcon = "overlayModal-module--closeIcon--qaItr";
export var closed = "overlayModal-module--closed--uTl1U";
export var enterButton = "overlayModal-module--enterButton--wds5s";
export var forAllServices = "overlayModal-module--forAllServices--Fz3Ek";
export var hidden = "overlayModal-module--hidden--GU97W";
export var importantGuidelines = "overlayModal-module--importantGuidelines--9-nN+";
export var logoWrapper = "overlayModal-module--logoWrapper--+mzYw";
export var message = "overlayModal-module--message--q3Zym";
export var messageWrapper = "overlayModal-module--messageWrapper--mRkT4";
export var modalContentGrid = "overlayModal-module--modalContentGrid--Xqg-r";
export var modalContentWrapper = "overlayModal-module--modalContentWrapper--oFmMe";
export var modalWrapper = "overlayModal-module--modalWrapper--zlV0L";
export var safeDistance = "overlayModal-module--safeDistance--g97Ho";
export var specialBulletin = "overlayModal-module--specialBulletin--XSlSJ";
export var thankYou = "overlayModal-module--thankYou--D42EX";
export var wrapper = "overlayModal-module--wrapper--PsDy2";