// extracted by mini-css-extract-plugin
export var carousel = "newsCarousel-module--carousel--Akvbl";
export var desktopNewsItemLeft = "newsCarousel-module--desktopNewsItemLeft--KAVVu";
export var desktopNewsItemRight = "newsCarousel-module--desktopNewsItemRight---+LSy";
export var newsFlex = "newsCarousel-module--newsFlex--cjS7b";
export var newsItemDescription = "newsCarousel-module--newsItemDescription--IGSS7";
export var newsItemImage = "newsCarousel-module--newsItemImage--qd2yF";
export var newsItemImageLink = "newsCarousel-module--newsItemImageLink--noZXM";
export var newsItemLink = "newsCarousel-module--newsItemLink--xXhEH";
export var newsItemTitle = "newsCarousel-module--newsItemTitle--btm2x";
export var newsItemWrapper = "newsCarousel-module--newsItemWrapper--IiNO6";
export var sliderGrid = "newsCarousel-module--sliderGrid--IvZpM";
export var wrapper = "newsCarousel-module--wrapper--E1gyQ";