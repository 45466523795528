import React, { useContext } from "react"
import * as overlayModalStyles from "./overlayModal.module.scss"
import { IoMdClose } from "react-icons/io"
import { GlobalStateContext } from "../../Context/GlobalContextProvider"

const OverlayModal = () => {
  const context = useContext(GlobalStateContext)

  return (
    <GlobalStateContext.Consumer>
      {context => (
        <div
          id="covid-modal"
          role="dialog"
          className={
            context.showCovidModal
              ? `${overlayModalStyles.block} ${overlayModalStyles.wrapper}`
              : `${overlayModalStyles.wrapper} ${overlayModalStyles.hidden}`
          }
        >
          <div className={overlayModalStyles.modalWrapper}>
            <div className={overlayModalStyles.modalContentWrapper}>
              <div className={overlayModalStyles.modalContentGrid}>
                <div className={overlayModalStyles.bulletinWrapper}>
                  <h2 className={overlayModalStyles.specialBulletin}>
                    Covid-19 Guidelines
                  </h2>
                </div>
                <div className={overlayModalStyles.messageWrapper}>
                  <p className={overlayModalStyles.message}>
                    <span className={overlayModalStyles.appointmentOnly}>
                      Modern Eye is open by appointment only.
                    </span>{" "}
                    <span className={overlayModalStyles.importantGuidelines}>
                      Here are important guidelines to keep in mind. Adherence
                      to these and other social distancing policies will help us
                      all navigate this time together :
                    </span>
                  </p>
                  <ul>
                    <li>
                      All patients and customers will require a mask throughout
                      their service
                    </li>
                    <li>
                      If you, or someone close by you has exhibited symptoms of
                      COVID-19 in the last two weeks, please re-schedule for
                      everyone’s protection
                    </li>
                    <li>
                      All appointment times will be held firm to allow for
                      proper cleaning / sterilization.
                    </li>
                    <li className={overlayModalStyles.safeDistance}>
                      Please be mindful of others while shopping by maintaining
                      a safe distance
                    </li>
                  </ul>
                  <p className={overlayModalStyles.thankYou}>
                    Thank you for your co-operation! We look forward to seeing
                    you and wish you all the best. Stay safe! <br /> -Dr. Chris
                    Anastasiou and all of us at Modern Eye
                  </p>
                </div>
                <button
                  onClick={() => context.closeCovidModal()}
                  className={overlayModalStyles.enterButton}
                >
                  Enter Site
                </button>
              </div>
              <button
                id="modal-close-button"
                tabIndex={0}
                onClick={() => context.closeCovidModal()}
              >
                <IoMdClose className={overlayModalStyles.closeIcon} />
              </button>
            </div>
          </div>
        </div>
      )}
    </GlobalStateContext.Consumer>
  )
}

export default OverlayModal
