import React from "react"
import * as emailSignupStyles from "./emailSignup.module.scss"

const EmailSignup = () => {
  return (
    <div id="mc_embed_shell" className={emailSignupStyles.wrapper}>
      <div id="mc_embed_signup">
        <form
          action="https://modern-eye.us8.list-manage.com/subscribe/post?u=fa1ab8f7245bd71fe4810384d&amp;id=7a3c454013&amp;f_id=0092f3e3f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_self"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <h2>Sign Up for Our Newsletter</h2>
            <div
              className="indicates-required"
              style={{
                marginBottom: "1rem",
              }}
            >
              <span className="asterisk">*</span> indicates required
            </div>
            <div className="mc-field-group">
              <label for="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
              />
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            {/* This input field is intended to prevent bots from filling out the form */}
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              <input
                type="text"
                name="b_fa1ab8f7245bd71fe4810384d_7a3c454013"
                tabIndex="-1"
                value=""
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmailSignup
