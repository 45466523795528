import * as React from "react"
import HeroArticle from "../components/index/heroArticle"
import HeroImage from "../components/index/heroImage"
import Seo from "../components/seo"
import ContactUs from "../components/index/contactUs"
import TheLatestNews from "../components/index/theLatestNews"
import InstagramFeed from "../components/index/instagramFeed"
import EmailSignup from "../components/index/emailSignup"
import ImageModal from "../components/index/ImageModal"
import { ModalProvider } from "../Context/ModalContext"

const IndexPage = () => (
  <div>
    <ModalProvider>
      <Seo title="Home" />
      <ImageModal />
      <div>
        <HeroImage />
        <HeroArticle />
        <ContactUs />
        <TheLatestNews />
        <InstagramFeed />
        <EmailSignup />
      </div>
    </ModalProvider>
  </div>
)

export default IndexPage
