import React from "react"
import * as heroArticleStyles from "./heroArticle.module.scss"
import { StaticImage } from "gatsby-plugin-image"

const HeroArticle = () => {
  return (
    <div className={heroArticleStyles.wrapper}>
      <h1 className={heroArticleStyles.visuallyHiddenHeader}>
        Eyecare and Eyewear Extroardinaire
      </h1>
      <StaticImage
        className={heroArticleStyles.extroardinaire}
        alt="Fancy Text that reads 'Eyecare and Eyewear Extroardinaire'"
        src="../../images/index/eyewear-extraordinaire.png"
        placeholder="blurred"
      />
      <p className={heroArticleStyles.article}>
        Modern Eye is a unique full-service optical shop and optometrist’s
        office with two locations in Philadelphia: Center City at 145 South 13th
        Street at the northeast corner of 13th and Walnut Streets - and
        University City on the campus of the University of Pennsylvania at 3419
        Walnut Street.
      </p>
    </div>
  )
}

export default HeroArticle
