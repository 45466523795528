import React, { useContext, useEffect } from "react"
import { ModalContext } from "../../Context/ModalContext"
import * as imageModalStyles from "./ImageModal.module.scss"

const ImageModal = () => {
  const { isModalVisible, hideModal } = useContext(ModalContext)

  // Define the cutoff date
  const cutoffDate = new Date("2024-11-02T04:00:00Z") // Midnight ET (UTC-4)

  // Check if the current date is before the cutoff date
  const isBeforeCutoff = new Date() < cutoffDate

  // Dismiss modal on clicking the overlay (backdrop)
  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      hideModal()
    }
  }

  if (!isModalVisible || !isBeforeCutoff) return null

  return (
    <div className={imageModalStyles.modalOverlay} onClick={handleOverlayClick}>
      <div className={imageModalStyles.modalContent}>
        <img
          src={require("../../images/index/ahlempromo.jpeg").default}
          alt="Ahlem Trunk Show | November 1, 2024 | 12pm - 6pm | modern eye center city"
          className={imageModalStyles.modalImage}
        />
        <button
          onClick={hideModal}
          className={imageModalStyles.modalCloseButton}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default ImageModal
