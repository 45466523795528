import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as heroImageStyles from "./heroImage.module.scss"

const HeroImage = () => {
  return (
    <div className={heroImageStyles.wrapper}>
      <StaticImage
        className={heroImageStyles.muscleman}
        src="../../images/hero.png"
        formats={["AUTO", "WEBP"]}
        alt="Illustration of Muscular man with eyeball head, between two ornate, golden columns. He is wearing leopard print underwear. "
        placeholder="tracedSVG"
        tracedSVGOptions="dominantColor"
      />
    </div>
  )
}

export default HeroImage
