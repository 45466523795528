import React, { createContext, useState, useEffect } from "react"

export const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const modalSeen = sessionStorage.getItem("modalSeen")
    if (!modalSeen) {
      setIsModalVisible(true)
      sessionStorage.setItem("modalSeen", "true")
    }
  }, [])

  const hideModal = () => setIsModalVisible(false)

  return (
    <ModalContext.Provider value={{ isModalVisible, hideModal }}>
      {children}
    </ModalContext.Provider>
  )
}
