import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as newsStyles from "./theLatestNews.module.scss"
import NewsCarousel from "./newsCarousel"

const TheLatestNews = () => {
  const indexNews = useStaticQuery(graphql`
    query {
      stories: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/blog/" } }
        limit: 3
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date
              featuredImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            excerpt
          }
        }
      }
    }
  `)

  const { stories } = indexNews
  const storyOne = stories.edges[0].node
  const storyTwo = stories.edges[1].node
  const storyThree = stories.edges[2].node

  return (
    <section className={newsStyles.wrapper}>
      <div className={newsStyles.headerWrapper}>
        <h2 to="/news" className={newsStyles.theLatestNews}>
          <Link
            to="/news"
            className={newsStyles.latestLink}
            title="The Latest News"
          >
            THE LATEST NEWS
          </Link>
        </h2>
        <StaticImage
          className={newsStyles.newsCornice}
          src="../../images/index/cornice.png"
          alt=""
          placeholder="blurred"
        />
      </div>
      <NewsCarousel
        storyOne={storyOne}
        storyTwo={storyTwo}
        storyThree={storyThree}
      />
    </section>
  )
}

export default TheLatestNews
