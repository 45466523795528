import React from "react"
import { Link } from "gatsby"
import * as newsCarouselStyles from "./newsCarousel.module.scss"
import styles from "react-responsive-carousel/lib/styles/carousel.min.css"
import { GatsbyImage } from "gatsby-plugin-image"
import { Carousel } from "react-responsive-carousel"

const NewsCarousel = ({ storyOne, storyTwo, storyThree }) => {
  return (
    <div className={newsCarouselStyles.wrapper}>
      {/* will be rendered on mobile */}
      <div aria-hidden="true" className={newsCarouselStyles.sliderGrid}>
        <Carousel
          swipeScrollTolerance={20}
          preventMovementUntilSwipeScrollTolerance={true}
          showStatus={false}
          showThumbs={false}
          className={newsCarouselStyles.carousel}
        >
          <Link
            to={`../news${storyOne.fields.slug}`}
            className={newsCarouselStyles.newsItemWrapper}
            title={storyOne.frontmatter.title}
          >
            <GatsbyImage
              className={newsCarouselStyles.newsItemImage}
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              imgStyle={{ objectFit: `cover` }}
              image={
                storyOne.frontmatter.featuredImage.childImageSharp
                  .gatsbyImageData
              }
              alt=""
            />

            <div className={newsCarouselStyles.newsItemLink}>
              <h3 className={newsCarouselStyles.newsItemTitle}>
                {storyOne.frontmatter.title}
              </h3>
              <p className={newsCarouselStyles.newsItemDescription}>
                {storyOne.excerpt}
              </p>
            </div>
          </Link>

          {/* covid story */}
          <Link
            to={`../news${storyTwo.fields.slug}`}
            className={newsCarouselStyles.newsItemWrapper}
            title={storyTwo.frontmatter.title}
          >
            <GatsbyImage
              className={newsCarouselStyles.newsItemImage}
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              imgStyle={{ objectFit: `cover` }}
              image={
                storyTwo.frontmatter.featuredImage.childImageSharp
                  .gatsbyImageData
              }
              alt=""
            />
            <div className={newsCarouselStyles.newsItemLink}>
              <h3 className={newsCarouselStyles.newsItemTitle}>
                {storyTwo.frontmatter.title}
              </h3>
              <p className={newsCarouselStyles.newsItemDescription}>
                {storyTwo.excerpt}
              </p>
            </div>
          </Link>

          {/* story 1 */}

          <Link
            to={`../news${storyThree.fields.slug}`}
            className={newsCarouselStyles.newsItemWrapper}
            title={storyThree.frontmatter.title}
          >
            <GatsbyImage
              className={newsCarouselStyles.newsItemImage}
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              imgStyle={{ objectFit: `cover` }}
              alt=""
              image={
                storyThree.frontmatter.featuredImage.childImageSharp
                  .gatsbyImageData
              }
            />
            <div className={newsCarouselStyles.newsItemLink}>
              <h3 className={newsCarouselStyles.newsItemTitle}>
                {storyThree.frontmatter.title}
              </h3>
              <p className={newsCarouselStyles.newsItemDescription}>
                {storyThree.excerpt}
              </p>
            </div>
          </Link>
        </Carousel>
      </div>
      {/* Will be rendered on desktop */}
      <div className={newsCarouselStyles.newsFlex} role="group">
        {/* story 1 */}
        <Link
          to={`../news${storyOne.fields.slug}`}
          className={`${newsCarouselStyles.newsItemWrapper} ${newsCarouselStyles.desktopNewsItemLeft}`}
          title={storyOne.frontmatter.title}
          aria-label="Story"
        >
          <GatsbyImage
            className={`${newsCarouselStyles.newsItemImage}`}
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            imgStyle={{ objectFit: `cover` }}
            image={
              storyOne.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            }
            alt=""
          />

          <div className={newsCarouselStyles.newsItemLink}>
            <h3 className={newsCarouselStyles.newsItemTitle}>
              {storyOne.frontmatter.title}
            </h3>
            <p className={newsCarouselStyles.newsItemDescription}>
              {storyOne.excerpt}
            </p>
          </div>
        </Link>
        {/* covid story */}
        <Link
          to={`../news${storyTwo.fields.slug}`}
          className={newsCarouselStyles.newsItemWrapper}
          title={storyTwo.frontmatter.title}
          aria-label="Story"
        >
          <GatsbyImage
            className={newsCarouselStyles.newsItemImage}
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            imgStyle={{ objectFit: `cover` }}
            image={
              storyTwo.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            }
            alt=""
          />
          <div className={newsCarouselStyles.newsItemLink}>
            <h3 className={newsCarouselStyles.newsItemTitle}>
              {storyTwo.frontmatter.title}
            </h3>
            <p className={newsCarouselStyles.newsItemDescription}>
              {storyTwo.excerpt}
            </p>
          </div>
        </Link>
        {/* story two */}
        <Link
          to={`../news${storyThree.fields.slug}`}
          className={`${newsCarouselStyles.newsItemWrapper} ${newsCarouselStyles.desktopNewsItemRight}`}
          title={storyThree.frontmatter.title}
          aria-label="Story"
        >
          <GatsbyImage
            className={newsCarouselStyles.newsItemImage}
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            imgStyle={{ objectFit: `cover` }}
            image={
              storyThree.frontmatter.featuredImage.childImageSharp
                .gatsbyImageData
            }
            alt=""
          />
          <div className={newsCarouselStyles.newsItemLink}>
            <h3 className={newsCarouselStyles.newsItemTitle}>
              {storyThree.frontmatter.title}
            </h3>
            <p className={newsCarouselStyles.newsItemDescription}>
              {storyThree.excerpt}
            </p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NewsCarousel
